<template>
  <div class="form-wrapper">
    <h4 class="text-center pb-3">SIGNUP FOR AN ACCOUNT</h4>
    <b-form
      id="free-signup-form"
      @submit.prevent="createAccount">
      <b-row align-v="center" no-gutters>
        <b-col cols="12" md="3" lg="3">
          <SocialMedia display="vertical" />
        </b-col>
        <b-col cols="12" md="2" lg="2">
          <h4 class="text-center">OR</h4>
        </b-col>
        <b-col cols="12" md="7" lg="7">
          <b-input-group prepend="Nickname">
            <b-form-input
              id="nickname"
              type="text"
              autocomplete="off"
              placeholder="Nickname"
              v-model.trim="$v.signup.nickname.$model"
              :state="!$v.signup.nickname.$error"
              @input="$v.signup.nickname.$touch()">
            </b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.nickname.required">
                Nickname is required.
              </span>
              <span v-if="!$v.signup.nickname.safeNickname &&
                $v.signup.nickname.required">
                Nickname not allowed.
              </span>
              <span v-if="!$v.signup.nickname.minLength &&
                $v.signup.nickname.safeNickname &&
                $v.signup.nickname.required">
                Nickname must be at least
                {{$v.signup.nickname.$params.minLength.min}}
                characters.
              </span>
              <span
                v-if="!$v.signup.nickname.uniqueNickname &&
                  $v.signup.nickname.minLength &&
                  $v.signup.nickname.safeNickname &&
                  $v.signup.nickname.required">
                Nickname is already used.
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-input-group prepend="First Name">
            <span class="tooltip" v-if="showTooltip.fname">
              Enter your first name. (Example: Roy)
            </span>
            <b-form-input
              id="fname"
              type="text"
              autocomplete="off"
              placeholder="First Name"
              v-model.trim="$v.signup.fname.$model"
              :state="!$v.signup.fname.$error"
              @input="$v.signup.fname.$touch()"
              @focusout="showTooltip.fname = false"
              @focus="showTooltip.fname = true">
            </b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.fname.required">
                First Name is required.
              </span>
              <span v-if="!$v.signup.fname.minLength">
                First Name must be at least
                {{$v.signup.fname.$params.minLength.min}}
                characters.
                <!-- Minimum length must be 2 characters. -->
              </span>
              <span v-if="!$v.signup.fname.safeFirstName">
                First Name not allowed.
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-input-group prepend="Last Name">
            <span class="tooltip" v-if="showTooltip.lname">
              Enter your lastname. (Example: Scott)
            </span>
            <b-form-input
              id="lname"
              type="text"
              autocomplete="off"
              placeholder="Last Name"
              v-model.trim="$v.signup.lname.$model"
              :state="!$v.signup.lname.$error"
              @input="$v.signup.lname.$touch()"
              @focusout="showTooltip.lname = false"
              @focus="showTooltip.lname = true">
            </b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.lname.required">
                Last Name is required.
              </span>
              <span v-if="!$v.signup.lname.minLength">
                Last Name must be at least
                {{$v.signup.lname.$params.minLength.min}}
                characters.
                <!-- Minimum length must be 2 characters. -->
              </span>
              <span v-if="!$v.signup.lname.safeLastName">
                Last Name not allowed.
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-row>
            <b-col cols="12" lg="12" xl="6">
              <b-input-group class="state-input" prepend="State">
                <b-form-select
                  class="form-control"
                  v-model="signup.state"
                  :options="states"
                  size="md">
                </b-form-select>
              </b-input-group>
            </b-col>
            <b-col cols="12" lg="12" xl="6">
              <b-input-group class="city-input" prepend="City">
                <b-form-select
                  class="form-control"
                  v-model="signup.city"
                  :options="cities"
                  size="md"
                  :disabled="cities.length < 2">
                </b-form-select>
              </b-input-group>
            </b-col>
          </b-row>

          <b-input-group prepend="Email">
            <span class="tooltip" v-if="showTooltip.email">
              Enter your email here! Use this everytime you log in.
            </span>
            <b-form-input
              id="email"
              type="email"
              autocomplete="off"
              placeholder="Email"
              v-model.trim="$v.signup.email.$model"
              :state="!$v.signup.email.$error"
              @focusout="showTooltip.email = false"
              @focus="showTooltip.email = true">
            </b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.email.required">
                Email is required.
              </span>
              <span v-if="!$v.signup.email.email">
                Please provide a valid email.
              </span>
              <span
                v-if="!$v.signup.email.uniqueEmail &&
                  $v.signup.email.email &&
                  $v.signup.email.required">
                Email is already registered.
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-input-group prepend="Password">
            <span class="tooltip" v-if="showTooltip.password">
              Create a password you will use to login. Make sure to
              remember this to log in the next time you visit.
            </span>
            <b-form-input
              id="password"
              type="password"
              autocomplete="off"
              placeholder="Password"
              v-model.trim="$v.signup.password.$model"
              :state="!$v.signup.password.$error"
              @focusout="showTooltip.password = false"
              @focus="showTooltip.password = true">
            </b-form-input>
            <b-form-invalid-feedback>
              <span v-if="!$v.signup.password.required">
                Password is required.
              </span>
              <span v-if="!$v.signup.password.minLength &&
                $v.signup.password.required">
                Password must be at least
                {{$v.signup.password.$params.minLength.min}}
                characters.
                <!-- Minimum Length should be 5 characters. -->
              </span>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-row align-v="center" align-h="center">
            <b-col class="left-col" cols="12" md="6" lg="6"></b-col>
            <b-col cols="6" md="4" lg="4">
              <b-button
                class="mb-2 btn-h3-orange pt-3 pb-3"
                size="sm"
                type="submit"
                variant="outline-secondary"
                :disabled="$v.signup.$invalid"
                block>
                Submit
              </b-button>
            </b-col>
            <b-col cols="6" md="4" lg="4">
              <b-button
                class="mb-2 btn-h3-orange pt-3 pb-3"
                size="sm"
                type="button"
                variant="outline-secondary"
                block
                @click="cancel">
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  const name = helpers.regex('name', /^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$/);

  import ProfanityMixin from '@/mixins/ProfanityMixin';
  import _ from 'lodash';
  import { required, minLength, email } from 'vuelidate/lib/validators';
  import { helpers } from 'vuelidate/lib/validators';

  export default {
    mixins : [
      ProfanityMixin,
    ],
    props : {
      birthdate : {
        type : String,
      },
      age : {
        type : Number,
      },
    },
    data() {
      return {
        loading : false,
        states  : [{
          value    : null,
          text     : 'Select State',
          disabled : true,
        }],
        cities : [{
          value    : null,
          text     : 'Select City',
          disabled : true,
        }],
        signup : {
          nickname : null,
          fname    : null,
          lname    : null,
          state    : null,
          city     : null,
          email    : null,
          password : null,
        },
        showTooltip : {
          fname    : false,
          lname    : false,
          email    : false,
          password : false,
        },
        safeNickname   : true,
        safeFirstName  : true,
        safeLastName   : true,
        uniqueEmail    : true,
        uniqueNickname : true,
      }
    },
    components : {
      'AppLoader'   : () => import('@/components/layout/AppLoader'),
      'SocialMedia' : () => import('@/components/common/SocialMediaButtons'),
    },
    watch : {
      'signup.fname'(val) {
        if (val) {
          this.safeFirstName = true;
          this.checkProfanity('safeFirstName', val);
        }
      },
      'signup.lname'(val) {
        if (val) {
          this.safeLastName = true;
          this.checkProfanity('safeLastName', val);
        }
      },
      'signup.state'(val) {
        if (val) {
          this.cities = [];
          this.signup.city = null;
          this.getCitiesOptions(val);
        }
      },
      'signup.email'(val) {
        this.uniqueEmail = true;
        if (val)
          this.checkEmail();
      },
      'signup.nickname'(val) {
        if (val) {
          this.safeNickname = true;
          this.checkProfanity('safeNickname', val);
          this.uniqueNickname = true;
          this.checkNickname();
        }
      },
    },
    methods : {

      /**
       * Create Account
       */
      createAccount() {
        this.loading = true;
        this.$http.post('auth/register', {
          nickname  : this.signup.nickname,
          firstname : this.signup.fname,
          lastname  : this.signup.lname,
          stateId   : this.signup.state,
          cityId    : this.signup.city,
          email     : this.signup.email,
          password  : this.signup.password,
          birthday  : this.birthdate,
          userType  : 2,
        }).then(async response => {
          if (response.status == 201) {
            await this.$http.post('auth/sendActivationEmail', {
              to : this.signup.email,
            });
            this.$gtag.event('registration', {
              'email'    : this.signup.username,
              'page'     : 'registration',
              'platform' : 'website',
            })

            this.$analytics.fbq.event('registration', {
              'email'    : this.signup.email,
              'page'     : 'registration',
              'platform' : 'website',
            });

            await this.$store.dispatch('auth/login', {
              email    : this.signup.email,
              password : this.signup.password,
            });
          }
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.loading = false;
        });
      },

      /**
       * Check if Email is registered
       */
      // eslint-disable-next-line no-undef
      checkEmail : _.debounce(function () {
        this.$http.get('api/user/email', {
          params : {
            email : this.signup.email,
          },
        }).then(response => {
          // swapped value since api returns true if email exists
          //   hence email is not unique
          this.uniqueEmail = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Check if Nickname exists
       */
      checkNickname : _.debounce(function() {
        this.$http.get('api/user/nickname', {
          params : {
            nickname : this.signup.nickname,
          },
        }).then(response => {
          this.uniqueNickname = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Get States Options
       * @param countryId
       */
      getStatesOptions(countryId) {
        this.$http.get('api/state/by_country', {
          params : {
            id : countryId,
          },
        }).then(response => {
          let i = 0;
          const len = response.data.length;

          while (i < len) {
            this.states.push({
              value : response.data[i].id,
              text  : response.data[i].name,
            });
            i++;
          }
        });
      },
      
      /**
       * Get Cities Options
       * @param stateId
       */
      getCitiesOptions(stateId) {
        this.cities = [{
          value    : null,
          text     : 'Select City',
          disabled : true,
        }];

        this.$http.get('api/city/by_state', {
          params : {
            id : stateId,
          },
        }).then(response => {
          let i = 0;
          const len = response.data.length;

          while(i < len) {
            this.cities.push({
              value : response.data[i].id,
              text  : response.data[i].name,
            });
            i++;
          }
        });
      },

      /**
       * Cancel Account Creation
       */
      cancel() {
        if (window.history.length > 2)
          this.$router.go(-1);
        else
          this.$router.push('/');
      },
    },
    mounted() {
      this.getStatesOptions(231);
    },
    validations : {
      signup : {
        nickname : {
          required,
          minLength : minLength(6),
          safeNickname() {
            return this.safeNickname;
          },
          uniqueNickname() {
            return this.uniqueNickname;
          },
        },
        fname : {
          required,
          minLength : minLength(2),
          name,
          safeFirstName() {
            return this.safeFirstName;
          },
        },
        lname : {
          required,
          minLength : minLength(2),
          name,
          safeLastName() {
            return this.safeLastName;
          },
        },
        state : { required },
        email : {
          email,
          required,
          uniqueEmail() {
            return this.uniqueEmail;
          },
        },
        password : {
          required,
          minLength : minLength(5),
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  h4 {
    -webkit-text-stroke: 2px $login-purple !important;
  }

  .form-wrapper {
    padding: 1.5rem;
    background-image: url(../../assets/images/signup-form-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 -2px 20px rgba(0,0,0,0.5),
      0 15px 20px rgba(0,0,0,0.55);
  }

  .form-control {
    height: auto;
  }

  /* Input */
  .input-group-text {
    width: 8em;
    background-color: $login-orange !important;
    color: #fff;
    font-family: "WickedMouse", cursive;
    font-size: 1em;
    -webkit-text-stroke: 1px $login-purple;
  }

  .invalid-feedback {
    position: absolute;
    top: 2.5em;
    left: 11.5em;
    width: auto;
    color: #fff;
  }

  .state-input, .city-input {
    .input-group-text {
      width: 4.5em;
    }
  }

  /* Tooltip */
  .tooltip {
    position: absolute;
    bottom: 115%;
    left: 20%;
    margin-top: .375rem;
    margin-left: 10px;
    border-radius: 6px;
    padding: 5px;
    background-color: $tooltip-color;
    color: #fff;
    font-size: 0.75rem;
    opacity: 1;
    transition: opacity .6s;
    text-align: center;
    visibility: visible;
    z-index: 1;
  }

  .tooltip::after {
    position: absolute;
    top: 100%;
    left: 10%;
    border-color: $tooltip-color transparent transparent  transparent ;
    border-style: solid;
    border-width: .5em;
    content: " ";
    margin-left: -5px;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h4 {
      font-size: 1.5em !important;
      -webkit-text-stroke: 1.5px $login-purple !important;
    }
    .input-group-text {
      display: none;
    }
    .left-col {
      display: none;
    }
    .invalid-feedback {
      left: 0;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    h4 {
      font-size: 1.75em !important;
      -webkit-text-stroke: 2px $login-purple !important;
    }
    .state-input, .city-input {
      .input-group-text {
        width: 8em;
      }
    }
    .left-col {
      display: none;
    }
    .invalid-feedback {
      left: 10.5em;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    h4 {
      font-size: 1.75em !important;
      -webkit-text-stroke: 2px $login-purple !important;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {}

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .state-input, .city-input {
      .input-group-text {
        width: 4.75em;
      }
    }
  }

  @media only screen and (min-width: $special) {
    .form-wrapper {
      padding: 1rem 1.5rem;
    }
    .state-input, .city-input {
      .input-group-text {
        width: 4.5em;
      }
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .form-wrapper {
      padding: 1.5rem;
    }
    .invalid-feedback {
      left: 10.5em;
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    h4 {
      margin-bottom: 2rem;
      font-size: 2em !important;
      -webkit-text-stroke: 2px $login-purple !important;
    }
    .form-wrapper {
      padding: 1.75rem 2rem;
    }
  }
</style>